import { defineStore } from 'pinia';
import { RevenueSummary } from '../types/revenue-summary.types';

export const useRevenueSummaryStore = defineStore('revenue-status-store', () => {
  const currentQuarter = ref<RevenueSummary>();

  function setCurrentQuarter(summary: RevenueSummary) {
    currentQuarter.value = summary;
  }

  return {
    currentQuarter,
    setCurrentQuarter,
  };
});
